import styled from "styled-components";

export const Link = styled.div`
  color: ${(props) => props.theme[props.$color]};
  font-weight: ${(p) => (p.$light ? "400" : "600")};
  font-size: inherit;
  line-height: 42px;
  cursor: pointer;
  display: inline-block;
  position: relative;
  margin-top: ${(p) => (p.$mt ? p.$mt : "0")}px;
  margin-bottom: ${(p) => (p.$mb ? p.$mb : "0")}px;

  svg {
    margin-left: 12px;
  }

  a {
    font-weight: ${(p) => (p.$light ? "400" : "600")};
    font-size: inherit;
    line-height: 2.625;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme[props.$color]};
    text-decoration: none;
  }

  &:after {
    content: "";
    height: 2px;
    background: ${(props) => props.theme[props.$color]};
    width: 0%;
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0.85;
    transition: all 150ms ease-in-out;
  }

  &:hover:after {
    width: 100%;
  }
`;
