import React from "react";
import { Link as LinkGatsby } from "gatsby";

import * as S from "./style";

import Arrow from "./arrow_alt.inline.svg";

export default function StyledLink({state, external, mt, mb, light, color = "primary", to, arrow, children, download}) {
  return (
    <S.Link $color={color} $mt={mt} $mb={mb} $light={light}>
      {external ? (
        <a href={to} target="_blank" rel="noopener noreferrer" download={download}>
          {children}
          {arrow && <Arrow />}
        </a>
      ) : (
        <LinkGatsby to={to} state={state}>
          {children}
          {arrow && <Arrow />}
        </LinkGatsby>
      )}
    </S.Link>
  );
}
