import React, { useState } from "react";
import * as S from "./style";

import { motion, AnimatePresence } from "framer-motion";

import Arrow from "../../icons/arrow.inline.svg";

export default function QuestionCard({ question, children }) {
  const [isCollapsed, setisCollapsed] = useState(true);
  return (
    <S.Card layout initial={{ opacity: 1 }}>
      <S.Header layout onClick={() => setisCollapsed(!isCollapsed)}>
        <h5>{question}</h5>
        <motion.div
          layout
          animate={isCollapsed ? { rotate: 0 } : { rotate: 180 }}
          className="icon"
        >
          <Arrow />
        </motion.div>
      </S.Header>
      <AnimatePresence>
        {!isCollapsed && (
          <S.Body
            layout
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <p>{children}</p>
          </S.Body>
        )}
      </AnimatePresence>
    </S.Card>
  );
}
