import React from 'react'
import Arrow from "../../images/testimonial/arrow2.inline.svg";

import * as S from "./style"

export default function Button({color, onClick, disabled}) {
    return (
        <S.Button onClick={onClick} $disabled={disabled} $color={color}>
            <Arrow />
        </S.Button>
    )
}
