import styled from "styled-components";

export const Testimonials = styled.div`
  &&& .cards {
    @media (min-width: ${(p) => p.theme.bp.tablet}) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 32px;
      align-items: stretch;
      justify-items: stretch;
    }
  }

  &&& .buttons {
    text-align: center;
    margin-top: 24px;
  }
`;
