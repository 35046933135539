import styled from "styled-components";

export const RadioButton = styled.label`
  font-weight: normal;
  font-size: ${(p) => (p.$size === "small" ? "14px" : "16px")};
  line-height: 21px;
  display: grid;
  grid-template-columns: 20px auto;
  gap: 12px;
  color: ${(p) => (p.$checked ? p.theme.primary : p.theme.text)};
  font-weight: ${(p) => (p.$checked ? 600 : 400)};
  cursor: pointer;

  &:hover {
    font-weight: 600;
  }

  & + & {
    margin-top: 12px;
  }

  &:focus-within {
    font-weight: 600;
  }

  input[type="radio"] {
    /* Remove most all native input styles */
    appearance: none;
    background-color: #e1e6e8;
    margin: 0;
    color: #e1e6e8;
    width: ${(p) => (p.$size === "small" ? "16px" : "20px")};
    height: ${(p) => (p.$size === "small" ? "16px" : "20px")};
    border: 2px solid #e1e6e8;
    border-radius: 50%;
    transform: translateY(2px);
    display: grid;
    place-content: center;
    margin-right: 12px;
  }

  input[type="radio"]::before {
    content: "";
    width: ${(p) => (p.$size === "small" ? "12px" : "16px")};
    height: ${(p) => (p.$size === "small" ? "12px" : "16px")};
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em ${(p) => p.theme.primary};
    background-color: CanvasText;
  }

  input[type="radio"]:checked {
    background-color: ${(p) => p.theme.primary};
    font-weight: 600;
  }

  input[type="radio"]:checked::before {
    color: ${(p) => p.theme.primary};
  }

  span::before {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
`;
