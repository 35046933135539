import styled from "styled-components";

export const Clientes = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 16px;
  justify-items: center;
  align-items: center;
  mix-blend-mode: darken;
  margin: 48px 0;
  filter: ${(p) => (p.$bw ? "grayscale(100%)" : "grayscale(0%)")};
  transform: ${(p) => (p.$size === "small" ? "scale(.85)" : "scale(1)")};

  img {
    max-width: 100%;
  }

  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    grid-template-columns: ${(p) =>
      p.$twoRows ? "repeat(6, 1fr)" : "repeat(4, 1fr)"};
    grid-template-rows: ${(p) =>
      p.$twoRows ? "repeat(2, 1fr)" : "repeat(3, 1fr)"};
  }
`;
