import React from "react";
import * as S from "./style";

import star from "../../images/testimonial/star.svg";
import aspas from "../../images/testimonial/“.svg";
import { GatsbyImage } from "gatsby-plugin-image";

export default function Testimonial(props) {
  return (
    <S.Card
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      $light={props.light}
    >
      <S.Foto>
        <GatsbyImage
          imgClassName="foto"
          image={props.foto}
          alt="Foto da pessoa que fez o depoimento"
        />
        <div className="aspas">
          <img src={aspas} alt="" />
        </div>
      </S.Foto>
      <S.Stars className="stars">
        <img src={star} alt="" />
        <img src={star} alt="" />
        <img src={star} alt="" />
        <img src={star} alt="" />
        <img src={star} alt="" />
      </S.Stars>
      <S.Texto>{props.children}</S.Texto>
      <S.Nome>{props.nome}</S.Nome>
      <S.Cargo>{props.cargo}</S.Cargo>
      <S.Empresa>{props.empresa}</S.Empresa>
    </S.Card>
  );
}
