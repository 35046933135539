import { motion } from "framer-motion";
import styled from "styled-components";

export const Card = styled(motion.div)`
  background: #ffffff;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
  border-radius: 0px 20px;
  padding: 16px 24px;
  margin-top: 16px;

  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    margin-top: 24px;
  }
`;

export const Header = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  min-height: 48px;

  h5 {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: ${(p) => p.theme.textDarkGray};
    font-weight: 600;
  }

  .icon {
    height: 24px;
    width: 24px;
    margin-right: -8px;
    margin-left: auto;
    color: ${(p) => p.theme.secondary};
    flex-shrink: 0;

    svg {
      height: 100%;
      width: 100%;
    }
  }
`;

export const Body = styled(motion.div)`
  p {
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    color: ${(p) => p.theme.textDarkGray};
    margin-top: 16px;
  }
`;
