import React from "react";
import QuestionCard from "../QuestionCard";
import * as S from "./style";

export default function QuestionWrapper({ questions }) {
  const middleIndex = Math.ceil(questions.length / 2);
  const firstHalf = questions.slice().splice(0, middleIndex);
  const secondHalf = questions.slice().splice(middleIndex);

  return (
    <S.Wrapper layout>
      <div>
        {firstHalf.map((question) => (
          <QuestionCard key={question.id} question={question.question}>
            {question.body}
          </QuestionCard>
        ))}
      </div>
      <div>
        {secondHalf.map((question) => (
          <QuestionCard key={question.id} question={question.question}>
            {question.body}
          </QuestionCard>
        ))}
      </div>
    </S.Wrapper>
  );
}