import { motion } from "framer-motion";
import styled from "styled-components";

export const Card = styled(motion.div)`
  &&& {
    background: ${(p) => p.theme.branco};
    padding: 36px 32px 44px;
    box-shadow: ${(p) =>
      p.$light
        ? "0px 4px 36px rgba(0, 0, 0, 0.15)"
        : "0px 4px 36px rgba(0, 0, 0, 0.25)"};
    border-radius: 0px 20px;
    text-align: center;
    margin: 32px 0;
    display: flex;
    flex-direction: column;

    /* @media (min-width: ${(p) => p.theme.bp.tablet}) {
      margin: 0 0 0 32px;

      &:first-child {
        margin: 0;
      }
    } */
  }
`;

export const Foto = styled.div`
  &&& {
    width: 120px;
    height: 120px;
    position: relative;
    margin: 0 auto;

    img.foto {
      width: 100%;
      border-radius: 120px;
    }

    .aspas {
      width: 52px;
      height: 52px;
      border-radius: 52px;
      background: ${(p) => p.theme.laranja};
      color: white;
      position: absolute;
      bottom: 0;
      right: -12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const Stars = styled.div`
  &&& {
    margin: 16px 0 32px;
  }
`;

export const Texto = styled.p`
  &&& {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: ${(p) => p.theme.textDarkerGray};
    margin-bottom: 24px;
  }
`;

export const Nome = styled.p`
  &&& {
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 32px;
    text-align: center;
    color: ${(p) => p.theme.textDarkerGray};
    margin-bottom: 0;
    margin-top: auto;
  }
`;

export const Empresa = styled.p`
  &&& {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: ${(p) => p.theme.textDarkerGray};
    margin: 0;
  }
`;

export const Cargo = styled.p`
  &&& {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: ${(p) => p.theme.textLightGray};
    margin: 0;
  }
`;
