import React from "react";
import { graphql } from "gatsby";
import Comprar from "../pagesFiles/comprar";
import Manutencao from "./manutencao";

export default function comprar({ data, location }) {
  const params = new URLSearchParams(location.search);

  return (
    // <Comprar
    //   isBasic={!location.state?.basic}
    //   questions={data.allFaqJson.nodes[0].taxsheets}
    // />
    <Manutencao />
  );
}

export const query = graphql`
  {
    allFaqJson {
      nodes {
        taxsheets {
          body
          id
          question
        }
      }
    }
  }
`;
