import styled from "styled-components";

export const Button = styled.button`
  width: 52px;
  height: 52px;
  border-radius: 52px;
  border: 2px solid ${(p) => (p.$color ? p.theme[p.$color] : p.theme.branco)};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  cursor: pointer;
  display: inline-block;
  color: ${(p) => (p.$color ? p.theme[p.$color] : p.theme.branco)};
  transition: all 200ms ease-in-out;
  opacity: ${p => p.$disabled ? ".5" : "1"};

  svg {
    transform: rotate(180deg);
  }

  & + & {
    margin-left: 20px;

    svg {
      transform: rotate(0deg);
    }
  }

  &:hover {
    background: ${(p) =>
      p.$disabled ? "none" : p.$color ? p.theme[p.$color] : p.theme.branco};
    color: ${(p) =>
      p.$disabled
        ? p.theme[p.$color]
        : p.$color
        ? p.theme.branco
        : p.theme.primary};
  }
`;
