import { motion } from "framer-motion";
import styled from "styled-components";

export const Wrapper = styled(motion.div)`
    display: block;

    @media (min-width: ${p => p.theme.bp.tablet}) {
        display: flex;
        align-items: flex-start;
        justify-content: center;

        & > div {
            display: block;
            width: 100%;

            &:first-child {
                margin-right: 32px;
            }
        }
    }
`