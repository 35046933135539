import React from "react";

import { GatsbyImage } from "gatsby-plugin-image";

import clientes from "../../images/clientes/clientes.png";

import { graphql, useStaticQuery } from "gatsby";

import * as S from "./style";

export default function Clientes({ twoRows, bw, size }) {
  const data = useStaticQuery(graphql`
    {
      clients: allClientesJson {
        nodes {
          id
          name
          logo {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);

  // const clientes = data.clients.nodes;
  // return (
  //   <S.Clientes $twoRows={twoRows} $bw={bw} $size={size}>
  //     {clientes.map((cliente) => (
  //       <GatsbyImage
  //         key={cliente.name}
  //         quality={100}
  //         image={cliente.logo.childImageSharp.gatsbyImageData}
  //         alt={`logo da empresa ${cliente.name}`}
  //       />
  //     ))}
  //   </S.Clientes>

  return (
    <img
    style={{marginTop: "2rem", marginBottom: "2rem", width: "100%"}}
      key="clientes"
      src={clientes}
      alt="logos de clientes da Taxcel"
    />
  );
}
